import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SvgIcon as MaterialSvgIcon } from '@mui/material';
import { IconDefinition as AntInternalIconDefinition } from '@ant-design/icons-svg/lib/types';

// Ant Design Icons imports
// https://ant.design/components/icon/
import {
    CloseCircleOutlined,
    ClockCircleOutlined,
    EditOutlined,
    InfoCircleOutlined,
    ReloadOutlined,
} from '@ant-design/icons-svg';

// FontAwesome Icons imports
// https://fontawesome.com/v5.15/icons?d=gallery
import {
    IconDefinition as FaRegularIconDefinition, // eslint-disable-line import/named
    faCheckCircle,
    faTimesCircle,
    faPlusSquare,
    faSave,
    faPaperPlane,
    faClock,
    faImage,
    faThumbsUp,
    faThumbsDown,
    faEdit,
    faEye,
    faUser,
    faBellSlash,
    faBell,
    faFileAlt,
    faComment,
    faStar as faStarEmpty,
} from '@fortawesome/free-regular-svg-icons';
import {
    IconDefinition as FaSolidIconDefinition, // eslint-disable-line import/named
    faExclamation,
    faCheck,
    faAt,
    faKey,
    faTimes,
    faPlus,
    faShareAlt,
    faQrcode,
    faClipboardCheck,
    faPrint,
    faPhone,
    faPhoneAlt,
    faChevronLeft,
    faChevronRight,
    faUndo,
    faUndoAlt,
    faAd,
    faTasks,
    faArrowCircleRight,
    faArrowCircleLeft,
    faChartLine,
    faPaintBrush,
    faChevronUp,
    faChevronDown,
    faRedo,
    faEllipsisH,
    faGlobe,
    faMinus,
    faBars,
    faCaretDown,
    faSignOutAlt,
    faStar as faStarFull,
    faLayerGroup,
    faMale,
    faSmoking,
    faWheelchair,
    faTrash,
    faSearch,
    faMap,
    faHome,
    faBell as faBellSolid,
    faCog,
    faBabyCarriage,
    faBirthdayCake,
    faTrophy,
    faChair,
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

// Material Design Icons imports
// https://fonts.google.com/icons
import { SvgIconComponent, LockOutlined, MessageOutlined } from '@mui/icons-material';

// Custom Icons imports
import ArrowCircleEnter from '../assets/icon/arrow-circle-enter.svg';
import ArrowCircleLeft from '../assets/icon/arrow-circle-left.svg';

// Icon Definition types
type FaIconDefinition = FaRegularIconDefinition | FaSolidIconDefinition;
type AntIconDefinition = {
    icon: {
        attrs: {
            [key: string]: string;
        };
        children: {
            tag: string;
            attrs: {
                [key: string]: string;
            };
        }[];
    };
};

const asRecord =
    <T,>() =>
    <K extends PropertyKey>(rec: Record<K, T>) =>
        rec;

// Font Awesome Icons map
const faIconMap = asRecord<FaIconDefinition>()({
    exclamationmark: faExclamation,
    checkmark: faCheck,
    at: faAt,
    key: faKey,
    completed: faCheckCircle,
    errorbadge: faTimesCircle,
    timesCircle: faTimesCircle,
    times: faTimes,
    plus: faPlus,
    map: faMap,
    plusSquare: faPlusSquare,
    shareAlt: faShareAlt,
    qrcode: faQrcode,
    whatsapp: faWhatsapp,
    save: faSave,
    clipboardCheck: faClipboardCheck,
    chair: faChair,
    print: faPrint,
    trophy: faTrophy,
    phone: faPhone,
    phoneAlt: faPhoneAlt,
    send: faPaperPlane,
    clock: faClock,
    image: faImage,
    chevronLeft: faChevronLeft,
    chevronRight: faChevronRight,
    undo: faUndo,
    undoAlt: faUndoAlt,
    ad: faAd,
    tasks: faTasks,
    arrowCircleRight: faArrowCircleRight,
    arrowCircleLeft: faArrowCircleLeft,
    chartLine: faChartLine,
    thumbsUp: faThumbsUp,
    thumbsDown: faThumbsDown,
    edit: faEdit,
    eye: faEye,
    delete: faTrash,
    paintBrush: faPaintBrush,
    chevronupsmall: faChevronUp,
    chevrondownsmall: faChevronDown,
    chevronDown: faChevronDown,
    user: faUser,
    bellSlash: faBellSlash,
    redo: faRedo,
    more: faEllipsisH,
    language: faGlobe,
    minus: faMinus,
    bars: faBars,
    caretDown: faCaretDown,
    bell: faBell,
    bellSolid: faBellSolid,
    fileAlt: faFileAlt,
    logout: faSignOutAlt,
    starEmpty: faStarEmpty,
    starFull: faStarFull,
    comment: faComment,
    layerGroup: faLayerGroup,
    person: faMale,
    cigarette: faSmoking,
    handicap: faWheelchair,
    search: faSearch,
    home: faHome,
    cog: faCog,
    babyCarriage: faBabyCarriage,
    cake: faBirthdayCake,
});

// Ant Design Icons map
const antIconMap = asRecord<AntInternalIconDefinition>()({
    antCloseCircleOutlined: CloseCircleOutlined,
    antClockCircleOutlined: ClockCircleOutlined,
    editLine: EditOutlined,
    infoCircle: InfoCircleOutlined,
    rotate: ReloadOutlined,
});

// Material Design Icons map
const materialIconMap = asRecord<SvgIconComponent>()({
    lock: LockOutlined,
    message: MessageOutlined,
});

// Custom Icons map
const customIconMap = asRecord<any>()({
    arrowEnter: ArrowCircleEnter,
    arrowLeft: ArrowCircleLeft,
});

export type IconName =
    | keyof typeof customIconMap
    | keyof typeof materialIconMap
    | keyof typeof antIconMap
    | keyof typeof faIconMap;

function renderAntIconDefinition(def: AntIconDefinition) {
    return (
        <svg {...def.icon.attrs} style={{ height: '1em' }}>
            {def.icon.children.map((child, i) => (
                <child.tag key={i} {...child.attrs} />
            ))}
        </svg>
    );
}

function renderCustomIconDefinition(def: string) {
    return <div className="customIcon" dangerouslySetInnerHTML={{ __html: def }} />; // eslint-disable-line react/no-danger
}

class IconRegistrationError extends Error {
    constructor(description: string) {
        super(description);
    }
}

function intersection<T>(...arrs: T[][]): T[] {
    const allAsOne = arrs.reduce((prev, cur) => [...prev, ...cur], []);
    const allSorted = allAsOne.sort();
    const repeated = allSorted.filter((val, i, arr) => arr.findIndex((v, j) => v === val && j !== i) !== -1);
    const uniqueRepeated = repeated.filter((val, i, arr) => arr.indexOf(val) === i);
    return uniqueRepeated;
}

function initialize(): void {
    const faIconKeys = Object.keys(faIconMap) as (keyof typeof faIconMap)[];
    const antIconKeys = Object.keys(antIconMap) as (keyof typeof antIconMap)[];
    const materialIconKeys = Object.keys(materialIconMap) as (keyof typeof materialIconMap)[];
    const customIconKeys = Object.keys(customIconMap) as (keyof typeof customIconMap)[];

    const commonKeys = intersection(faIconKeys, antIconKeys, materialIconKeys, customIconKeys);
    if (commonKeys.length !== 0) {
        throw new IconRegistrationError(
            'Attempted to register multiple icons to the same iconName(s): ' + commonKeys.join(', ')
        );
    }

    const icons: Partial<{ [key in IconName]: React.ReactElement }> = {};
    faIconKeys.forEach(iconKey => {
        icons[iconKey] = <FontAwesomeIcon icon={faIconMap[iconKey]} />;
    });
    antIconKeys.forEach(iconKey => {
        icons[iconKey] = renderAntIconDefinition(antIconMap[iconKey] as any);
    });
    materialIconKeys.forEach(iconKey => {
        icons[iconKey] = <MaterialSvgIcon component={materialIconMap[iconKey]} />;
    });
    customIconKeys.forEach(iconKey => {
        icons[iconKey] = renderCustomIconDefinition(customIconMap[iconKey]);
    });
    registerIcons({ icons });
}
export const PreRender = (): React.ReactElement => {
    return (
        <div id="preRender">
            <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20.5 13.5C19.9696 13.5 19.4609 13.2893 19.0858 12.9142C18.7107 12.5391 18.5 12.0304 18.5 11.5V2.5C18.5 1.96957 18.7107 1.46086 19.0858 1.08579C19.4609 0.710714 19.9696 0.5 20.5 0.5C21.0304 0.5 21.5391 0.710714 21.9142 1.08579C22.2893 1.46086 22.5 1.96957 22.5 2.5V11.5C22.5 12.0304 22.2893 12.5391 21.9142 12.9142C21.5391 13.2893 21.0304 13.5 20.5 13.5Z"
                    fill="#5BC6F9"
                />
                <path
                    d="M20.5 1C20.8978 1 21.2794 1.15804 21.5607 1.43934C21.842 1.72064 22 2.10218 22 2.5V11.5C22 11.8978 21.842 12.2794 21.5607 12.5607C21.2794 12.842 20.8978 13 20.5 13C20.1022 13 19.7206 12.842 19.4393 12.5607C19.158 12.2794 19 11.8978 19 11.5V2.5C19 2.10218 19.158 1.72064 19.4393 1.43934C19.7206 1.15804 20.1022 1 20.5 1ZM20.5 0C19.837 0 19.2011 0.263392 18.7322 0.732233C18.2634 1.20107 18 1.83696 18 2.5V11.5C18 12.163 18.2634 12.7989 18.7322 13.2678C19.2011 13.7366 19.837 14 20.5 14C21.163 14 21.7989 13.7366 22.2678 13.2678C22.7366 12.7989 23 12.163 23 11.5V2.5C23 1.83696 22.7366 1.20107 22.2678 0.732233C21.7989 0.263392 21.163 0 20.5 0Z"
                    fill="white"
                />
                <path
                    d="M16 14.7099C15.6493 14.7096 15.3048 14.617 15.0012 14.4415C14.6975 14.2659 14.4454 14.0136 14.27 13.7099L9.76999 5.90991C9.50525 5.45098 9.43343 4.90575 9.5703 4.39391C9.70716 3.88208 10.0415 3.44546 10.5 3.17991C10.8038 3.00366 11.1487 2.91054 11.5 2.90991C11.8507 2.91027 12.1952 3.00285 12.4988 3.17837C12.8025 3.35389 13.0546 3.60617 13.23 3.90991L17.73 11.7099C17.9932 12.1672 18.0651 12.7099 17.93 13.2199C17.8629 13.4745 17.7462 13.7133 17.5866 13.9226C17.427 14.132 17.2277 14.3078 17 14.4399C16.6953 14.614 16.3509 14.707 16 14.7099Z"
                    fill="#69CFFF"
                    fillOpacity="0.92"
                />
                <path
                    d="M11.5 3.40991C11.7631 3.41174 12.0212 3.48181 12.249 3.61327C12.4769 3.74474 12.6667 3.93309 12.8 4.15991L17.3 11.9999C17.4978 12.3418 17.5518 12.7483 17.45 13.1299C17.4009 13.321 17.3135 13.5002 17.1932 13.6566C17.0729 13.813 16.9221 13.9434 16.75 14.0399C16.524 14.1775 16.2645 14.2501 16 14.2499C15.7369 14.2481 15.4788 14.178 15.251 14.0466C15.0231 13.9151 14.8333 13.7267 14.7 13.4999L10.2 5.69991C10.0016 5.35456 9.9477 4.94482 10.05 4.55991C10.1002 4.36925 10.188 4.19054 10.3082 4.03427C10.4284 3.878 10.5786 3.74732 10.75 3.64991C10.9786 3.51988 11.237 3.451 11.5 3.44991V3.40991ZM11.5 2.44991C11.0604 2.44904 10.6286 2.56648 10.25 2.78991C9.96496 2.95269 9.71491 3.17023 9.51425 3.43C9.31359 3.68977 9.16627 3.98665 9.08077 4.30357C8.99527 4.62048 8.97327 4.95118 9.01604 5.27662C9.05881 5.60207 9.16551 5.91585 9.33 6.19991L13.83 13.9999C14.0563 14.3719 14.3752 14.6789 14.7555 14.8909C15.1358 15.103 15.5646 15.2129 16 15.2099C16.4396 15.2108 16.8714 15.0933 17.25 14.8699C17.535 14.7071 17.7851 14.4896 17.9857 14.2298C18.1864 13.9701 18.3337 13.6732 18.4192 13.3563C18.5047 13.0393 18.5267 12.7086 18.484 12.3832C18.4412 12.0578 18.3345 11.744 18.17 11.4599L13.67 3.65991C13.4486 3.2805 13.1319 2.96554 12.7513 2.74628C12.3706 2.52702 11.9393 2.41106 11.5 2.40991V2.44991Z"
                    fill="white"
                />
                <path
                    d="M12.7 18C12.3482 18.0038 12.0021 17.9104 11.7 17.73L3.9 13.23C3.51973 13.0094 3.22273 12.6697 3.05494 12.2633C2.88715 11.857 2.85791 11.4067 2.97175 10.9821C3.08559 10.5575 3.33617 10.1822 3.68472 9.91428C4.03327 9.64638 4.46038 9.50078 4.9 9.5C5.25126 9.50062 5.59616 9.59375 5.9 9.77L13.7 14.27C14.0803 14.4906 14.3773 14.8303 14.5451 15.2367C14.7128 15.643 14.7421 16.0933 14.6282 16.5179C14.5144 16.9425 14.2638 17.3178 13.9153 17.5857C13.5667 17.8536 13.1396 17.9992 12.7 18Z"
                    fill="#8BDAFF"
                />
                <path
                    d="M4.91001 10C5.17305 10.0011 5.43136 10.07 5.66001 10.2L13.46 14.7C13.7472 14.8653 13.9716 15.1211 14.0981 15.4274C14.2246 15.7336 14.2462 16.0732 14.1594 16.393C14.0726 16.7128 13.8823 16.9949 13.6183 17.1951C13.3543 17.3954 13.0314 17.5026 12.7 17.5C12.44 17.5 12.1847 17.431 11.96 17.3L4.16001 12.8C3.98895 12.7022 3.839 12.5714 3.71885 12.4152C3.59869 12.259 3.51072 12.0805 3.46001 11.89C3.40012 11.6676 3.39226 11.4343 3.43706 11.2084C3.48185 10.9824 3.5781 10.7698 3.71832 10.587C3.85855 10.4042 4.03899 10.2562 4.24565 10.1544C4.45231 10.0526 4.67964 9.99979 4.91001 10ZM4.91001 9.00003C4.35809 8.99813 3.82106 9.17892 3.38265 9.51422C2.94425 9.84951 2.62912 10.3205 2.48641 10.8536C2.34371 11.3868 2.38144 11.9522 2.59373 12.4616C2.80602 12.9711 3.18094 13.396 3.66001 13.67L11.46 18.17C11.8381 18.3848 12.2652 18.4984 12.7 18.5C13.2563 18.5134 13.8011 18.3407 14.2482 18.0094C14.6953 17.6782 15.0191 17.2072 15.1683 16.6712C15.3175 16.1351 15.2836 15.5646 15.072 15.05C14.8604 14.5354 14.4832 14.1061 14 13.83L6.20001 9.33003C5.80701 9.10728 5.36171 8.99336 4.91001 9.00003Z"
                    fill="white"
                />
                <path
                    d="M2.5 22.5C1.96957 22.5 1.46086 22.2893 1.08579 21.9142C0.710714 21.5391 0.5 21.0304 0.5 20.5C0.5 19.9696 0.710714 19.4609 1.08579 19.0858C1.46086 18.7107 1.96957 18.5 2.5 18.5H11.5C12.0304 18.5 12.5391 18.7107 12.9142 19.0858C13.2893 19.4609 13.5 19.9696 13.5 20.5C13.5 21.0304 13.2893 21.5391 12.9142 21.9142C12.5391 22.2893 12.0304 22.5 11.5 22.5H2.5Z"
                    fill="#A3E2FF"
                />
                <path
                    d="M11.5 19C11.8978 19 12.2794 19.158 12.5607 19.4393C12.842 19.7206 13 20.1022 13 20.5C13 20.8978 12.842 21.2794 12.5607 21.5607C12.2794 21.842 11.8978 22 11.5 22H2.5C2.10218 22 1.72064 21.842 1.43934 21.5607C1.15804 21.2794 1 20.8978 1 20.5C1 20.1022 1.15804 19.7206 1.43934 19.4393C1.72064 19.158 2.10218 19 2.5 19H11.5ZM11.5 18H2.5C1.83696 18 1.20107 18.2634 0.732233 18.7322C0.263392 19.2011 0 19.837 0 20.5C0 21.163 0.263392 21.7989 0.732233 22.2678C1.20107 22.7366 1.83696 23 2.5 23H11.5C12.163 23 12.7989 22.7366 13.2678 22.2678C13.7366 21.7989 14 21.163 14 20.5C14 19.837 13.7366 19.2011 13.2678 18.7322C12.7989 18.2634 12.163 18 11.5 18Z"
                    fill="white"
                />
                <path
                    d="M4.91001 31.5C4.47039 31.4993 4.04328 31.3537 3.69473 31.0858C3.34618 30.8179 3.0956 30.4426 2.98176 30.0179C2.86792 29.5933 2.89716 29.143 3.06495 28.7367C3.23274 28.3304 3.52974 27.9906 3.91001 27.77L11.71 23.27C12.0127 23.0911 12.3584 22.9977 12.71 23C13.1496 23.0008 13.5767 23.1464 13.9253 23.4143C14.2738 23.6822 14.5244 24.0575 14.6382 24.4821C14.7521 24.9068 14.7228 25.3571 14.5551 25.7634C14.3873 26.1697 14.0903 26.5095 13.71 26.73L5.91001 31.23C5.60617 31.4063 5.26127 31.4994 4.91001 31.5Z"
                    fill="#BAE8FF"
                />
                <path
                    d="M12.7 23.5001C13.0314 23.4975 13.3543 23.6047 13.6183 23.805C13.8823 24.0052 14.0726 24.2873 14.1594 24.6071C14.2462 24.9269 14.2246 25.2665 14.0981 25.5727C13.9716 25.879 13.7472 26.1348 13.46 26.3001L5.66 30.8001C5.31997 30.9837 4.92221 31.0289 4.54961 30.9265C4.17702 30.824 3.85834 30.5817 3.66 30.2501C3.46161 29.9047 3.4077 29.495 3.51 29.1101C3.5607 28.9196 3.64868 28.7411 3.76883 28.5849C3.88899 28.4287 4.03894 28.2979 4.21 28.2001L12 23.7001C12.2246 23.5691 12.48 23.5001 12.74 23.5001H12.7ZM12.74 22.5001C12.3052 22.5017 11.8781 22.6153 11.5 22.8301L3.7 27.3301C3.22381 27.6024 2.8504 28.0238 2.63734 28.5293C2.42429 29.0348 2.3834 29.5963 2.52099 30.1273C2.65857 30.6583 2.967 31.1294 3.39872 31.4678C3.83044 31.8062 4.36148 31.9933 4.91 32.0001C5.34815 31.9997 5.77877 31.886 6.16 31.6701L13.96 27.1701C14.4398 26.8956 14.8152 26.4698 15.0273 25.9593C15.2394 25.4489 15.2764 24.8825 15.1324 24.3488C14.9884 23.8151 14.6716 23.3441 14.2315 23.0096C13.7913 22.6751 13.2528 22.4959 12.7 22.5001H12.74Z"
                    fill="white"
                />
                <path
                    d="M11.5 38.09C11.1487 38.0894 10.8038 37.9963 10.5 37.82C10.0415 37.5545 9.70716 37.1179 9.5703 36.606C9.43343 36.0942 9.50525 35.549 9.76999 35.09L14.27 27.29C14.4454 26.9863 14.6975 26.734 15.0012 26.5585C15.3048 26.383 15.6493 26.2904 16 26.29C16.3509 26.293 16.6953 26.3859 17 26.56C17.2277 26.6922 17.427 26.868 17.5866 27.0773C17.7462 27.2867 17.8629 27.5255 17.93 27.78C18.0651 28.29 17.9932 28.8328 17.73 29.29L13.23 37.09C13.0546 37.3938 12.8025 37.6461 12.4988 37.8216C12.1952 37.9971 11.8507 38.0897 11.5 38.09Z"
                    fill="#D5F1FF"
                />
                <path
                    d="M16 26.79C16.2645 26.7898 16.524 26.8624 16.75 27C16.9221 27.0965 17.0729 27.2269 17.1932 27.3833C17.3135 27.5397 17.4009 27.7189 17.45 27.91C17.5409 28.2792 17.4872 28.6691 17.3 29L12.8 36.8C12.7016 36.9708 12.5705 37.1205 12.4142 37.2406C12.2578 37.3607 12.0794 37.4488 11.889 37.4999C11.6986 37.551 11.5 37.564 11.3046 37.5383C11.1091 37.5126 10.9207 37.4486 10.75 37.35C10.5786 37.2526 10.4284 37.1219 10.3082 36.9656C10.188 36.8094 10.1002 36.6307 10.05 36.44C9.9477 36.0551 10.0016 35.6453 10.2 35.3L14.7 27.5C14.8333 27.2732 15.0231 27.0848 15.251 26.9534C15.4788 26.8219 15.7369 26.7518 16 26.75V26.79ZM16 25.75C15.5607 25.7511 15.1294 25.8671 14.7487 26.0864C14.3681 26.3056 14.0514 26.6206 13.83 27L9.33 34.8C9.16551 35.0841 9.05881 35.3978 9.01604 35.7233C8.97327 36.0487 8.99527 36.3794 9.08077 36.6963C9.16627 37.0133 9.31359 37.3101 9.51425 37.5699C9.71491 37.8297 9.96496 38.0472 10.25 38.21C10.6286 38.4334 11.0604 38.5509 11.5 38.55C11.9393 38.5489 12.3706 38.4329 12.7513 38.2136C13.1319 37.9944 13.4486 37.6794 13.67 37.3L18.17 29.5C18.3345 29.2159 18.4412 28.9022 18.484 28.5767C18.5267 28.2513 18.5047 27.9206 18.4192 27.6037C18.3337 27.2867 18.1864 26.9899 17.9857 26.7301C17.7851 26.4703 17.535 26.2528 17.25 26.09C16.8675 25.8805 16.4359 25.777 16 25.79V25.75Z"
                    fill="white"
                />
                <path
                    d="M20.5 40.5C19.9696 40.5 19.4609 40.2893 19.0858 39.9142C18.7107 39.5391 18.5 39.0304 18.5 38.5V29.5C18.5 28.9696 18.7107 28.4609 19.0858 28.0858C19.4609 27.7107 19.9696 27.5 20.5 27.5C21.0304 27.5 21.5391 27.7107 21.9142 28.0858C22.2893 28.4609 22.5 28.9696 22.5 29.5V38.5C22.5 39.0304 22.2893 39.5391 21.9142 39.9142C21.5391 40.2893 21.0304 40.5 20.5 40.5Z"
                    fill="#EBF8FF"
                />
                <path
                    d="M20.5 28C20.8978 28 21.2794 28.158 21.5607 28.4393C21.842 28.7206 22 29.1022 22 29.5V38.5C22 38.8978 21.842 39.2794 21.5607 39.5607C21.2794 39.842 20.8978 40 20.5 40C20.1022 40 19.7206 39.842 19.4393 39.5607C19.158 39.2794 19 38.8978 19 38.5V29.5C19 29.1022 19.158 28.7206 19.4393 28.4393C19.7206 28.158 20.1022 28 20.5 28ZM20.5 27C19.837 27 19.2011 27.2634 18.7322 27.7322C18.2634 28.2011 18 28.837 18 29.5V38.5C18 39.163 18.2634 39.7989 18.7322 40.2678C19.2011 40.7366 19.837 41 20.5 41C21.163 41 21.7989 40.7366 22.2678 40.2678C22.7366 39.7989 23 39.163 23 38.5V29.5C23 28.837 22.7366 28.2011 22.2678 27.7322C21.7989 27.2634 21.163 27 20.5 27Z"
                    fill="white"
                />
                <path
                    d="M29.5 38.09C29.1493 38.0897 28.8048 37.9971 28.5012 37.8216C28.1975 37.6461 27.9454 37.3938 27.77 37.09L23.27 29.29C23.0068 28.8328 22.9349 28.29 23.07 27.78C23.1371 27.5255 23.2538 27.2867 23.4134 27.0773C23.573 26.868 23.7723 26.6922 24 26.56C24.3047 26.3859 24.649 26.293 25 26.29C25.3507 26.2904 25.6952 26.383 25.9988 26.5585C26.3025 26.734 26.5546 26.9863 26.73 27.29L31.23 35.09C31.4947 35.549 31.5666 36.0942 31.4297 36.606C31.2928 37.1179 30.9585 37.5545 30.5 37.82C30.1962 37.9963 29.8513 38.0894 29.5 38.09Z"
                    fill="#0096DE"
                />
                <path
                    d="M25 26.79C25.2631 26.7918 25.5211 26.8619 25.749 26.9934C25.9769 27.1248 26.1667 27.3132 26.3 27.54L30.8 35.34C30.9067 35.5075 30.9792 35.6945 31.0136 35.8901C31.0479 36.0857 31.0433 36.2862 31 36.48C30.9498 36.6707 30.862 36.8494 30.7418 37.0056C30.6216 37.1619 30.4714 37.2926 30.3 37.39C29.96 37.5736 29.5622 37.6189 29.1896 37.5164C28.817 37.414 28.4983 37.1716 28.3 36.84L23.7 29C23.5022 28.6581 23.4482 28.2517 23.55 27.87C23.5991 27.6789 23.6865 27.4997 23.8068 27.3433C23.9271 27.1869 24.0779 27.0565 24.25 26.96C24.476 26.8224 24.7355 26.7498 25 26.75V26.79ZM25 25.75C24.5604 25.7491 24.1286 25.8666 23.75 26.09C23.465 26.2528 23.2149 26.4703 23.0143 26.7301C22.8136 26.9899 22.6663 27.2867 22.5808 27.6037C22.4953 27.9206 22.4733 28.2513 22.516 28.5767C22.5588 28.9022 22.6655 29.2159 22.83 29.5L27.33 37.3C27.5514 37.6794 27.8681 37.9944 28.2487 38.2136C28.6294 38.4329 29.0607 38.5489 29.5 38.55C29.9396 38.5509 30.3714 38.4334 30.75 38.21C31.035 38.0472 31.2851 37.8297 31.4857 37.5699C31.6864 37.3101 31.8337 37.0133 31.9192 36.6964C32.0047 36.3794 32.0267 36.0487 31.984 35.7233C31.9412 35.3978 31.8345 35.0841 31.67 34.8L27.17 27C26.9437 26.628 26.6248 26.321 26.2445 26.109C25.8642 25.8969 25.4354 25.787 25 25.79V25.75Z"
                    fill="white"
                />
                <path
                    d="M36.09 31.5C35.7388 31.4994 35.3939 31.4063 35.09 31.23L27.29 26.73C26.9097 26.5095 26.6127 26.1697 26.445 25.7634C26.2772 25.3571 26.2479 24.9068 26.3618 24.4821C26.4756 24.0575 26.7262 23.6822 27.0747 23.4143C27.4233 23.1464 27.8504 23.0008 28.29 23C28.6417 22.9977 28.9873 23.0911 29.29 23.27L37.09 27.77C37.4703 27.9906 37.7673 28.3304 37.9351 28.7367C38.1029 29.143 38.1321 29.5933 38.0183 30.0179C37.9044 30.4426 37.6538 30.8179 37.3053 31.0858C36.9567 31.3537 36.5296 31.4993 36.09 31.5Z"
                    fill="#07AFFF"
                />
                <path
                    d="M28.3 23.5C28.56 23.5 28.8154 23.569 29.04 23.7L36.84 28.2C37.011 28.2978 37.161 28.4287 37.2812 28.5849C37.4013 28.7411 37.4893 28.9195 37.54 29.11C37.5999 29.3324 37.6077 29.5657 37.5629 29.7917C37.5181 30.0176 37.4219 30.2303 37.2817 30.413C37.1415 30.5958 36.961 30.7438 36.7544 30.8456C36.5477 30.9474 36.3204 31.0002 36.09 31C35.827 30.9989 35.5686 30.93 35.34 30.8L27.54 26.3C27.2528 26.1347 27.0284 25.8789 26.9019 25.5726C26.7754 25.2664 26.7538 24.9268 26.8406 24.607C26.9274 24.2872 27.1177 24.0051 27.3817 23.8049C27.6457 23.6046 27.9686 23.4974 28.3 23.5ZM28.3 22.5C27.7437 22.4867 27.1989 22.6593 26.7518 22.9906C26.3047 23.3218 25.9809 23.7928 25.8317 24.3288C25.6825 24.8649 25.7164 25.4354 25.928 25.95C26.1396 26.4646 26.5168 26.894 27 27.17L34.8 31.67C35.1812 31.886 35.6118 31.9996 36.05 32C36.6019 32.0019 37.1389 31.8211 37.5773 31.4858C38.0158 31.1505 38.3309 30.6796 38.4736 30.1464C38.6163 29.6133 38.5786 29.0479 38.3663 28.5384C38.154 28.0289 37.7791 27.604 37.3 27.33L29.5 22.83C29.1219 22.6153 28.6948 22.5016 28.26 22.5H28.3Z"
                    fill="white"
                />
                <path
                    d="M29.5 22.5C28.9696 22.5 28.4609 22.2893 28.0858 21.9142C27.7107 21.5391 27.5 21.0304 27.5 20.5C27.5 19.9696 27.7107 19.4609 28.0858 19.0858C28.4609 18.7107 28.9696 18.5 29.5 18.5H38.5C39.0304 18.5 39.5391 18.7107 39.9142 19.0858C40.2893 19.4609 40.5 19.9696 40.5 20.5C40.5 21.0304 40.2893 21.5391 39.9142 21.9142C39.5391 22.2893 39.0304 22.5 38.5 22.5H29.5Z"
                    fill="#1CB6FF"
                />
                <path
                    d="M38.5 19C38.8978 19 39.2794 19.158 39.5607 19.4393C39.842 19.7206 40 20.1022 40 20.5C40 20.8978 39.842 21.2794 39.5607 21.5607C39.2794 21.842 38.8978 22 38.5 22H29.5C29.1022 22 28.7206 21.842 28.4393 21.5607C28.158 21.2794 28 20.8978 28 20.5C28 20.1022 28.158 19.7206 28.4393 19.4393C28.7206 19.158 29.1022 19 29.5 19H38.5ZM38.5 18H29.5C28.837 18 28.2011 18.2634 27.7322 18.7322C27.2634 19.2011 27 19.837 27 20.5C27 21.163 27.2634 21.7989 27.7322 22.2678C28.2011 22.7366 28.837 23 29.5 23H38.5C39.163 23 39.7989 22.7366 40.2678 22.2678C40.7366 21.7989 41 21.163 41 20.5C41 19.837 40.7366 19.2011 40.2678 18.7322C39.7989 18.2634 39.163 18 38.5 18Z"
                    fill="white"
                />
                <path
                    d="M28.3 18C27.8604 17.9992 27.4333 17.8536 27.0847 17.5857C26.7362 17.3178 26.4856 16.9425 26.3717 16.5179C26.2579 16.0933 26.2871 15.643 26.4549 15.2367C26.6227 14.8303 26.9197 14.4906 27.3 14.27L35.1 9.77C35.4038 9.59375 35.7487 9.50062 36.1 9.5C36.5396 9.50078 36.9667 9.64638 37.3153 9.91428C37.6638 10.1822 37.9144 10.5575 38.0282 10.9821C38.1421 11.4067 38.1128 11.857 37.945 12.2633C37.7773 12.6697 37.4803 13.0094 37.1 13.23L29.3 17.73C28.9973 17.909 28.6516 18.0023 28.3 18Z"
                    fill="#37BFFF"
                />
                <path
                    d="M36.09 10C36.3204 9.99976 36.5477 10.0526 36.7544 10.1544C36.961 10.2562 37.1415 10.4042 37.2817 10.587C37.4219 10.7697 37.5182 10.9824 37.563 11.2083C37.6078 11.4343 37.5999 11.6676 37.54 11.89C37.4893 12.0804 37.4013 12.2589 37.2812 12.4151C37.161 12.5713 37.0111 12.7021 36.84 12.8L29 17.3C28.7754 17.4309 28.52 17.5 28.26 17.5C27.9286 17.5026 27.6057 17.3954 27.3417 17.1951C27.0777 16.9948 26.8874 16.7128 26.8006 16.393C26.7138 16.0732 26.7354 15.7336 26.8619 15.4273C26.9884 15.1211 27.2128 14.8653 27.5 14.7L35.3 10.2C35.5287 10.07 35.787 10.0011 36.05 10H36.09ZM36.05 9C35.6119 9.00034 35.1812 9.11403 34.8 9.33L27 13.83C26.4245 14.1615 26.0042 14.7081 25.8317 15.3495C25.6592 15.9908 25.7485 16.6745 26.08 17.25C26.4115 17.8255 26.9581 18.2458 27.5995 18.4183C28.2408 18.5908 28.9245 18.5015 29.5 18.17L37.3 13.67C37.7762 13.3977 38.1496 12.9763 38.3627 12.4708C38.5757 11.9653 38.6166 11.4038 38.479 10.8728C38.3414 10.3417 38.033 9.87071 37.6013 9.53228C37.1696 9.19386 36.6385 9.00682 36.09 9H36.05Z"
                    fill="white"
                />
                <path
                    d="M25 14.7099C24.649 14.707 24.3047 14.614 24 14.4399C23.7723 14.3078 23.573 14.132 23.4134 13.9226C23.2538 13.7133 23.1371 13.4745 23.07 13.2199C22.9349 12.7099 23.0068 12.1672 23.27 11.7099L27.77 3.90991C27.9454 3.60617 28.1975 3.35389 28.5012 3.17837C28.8048 3.00285 29.1493 2.91027 29.5 2.90991C29.8513 2.91054 30.1962 3.00366 30.5 3.17991C30.9585 3.44546 31.2928 3.88208 31.4297 4.39391C31.5666 4.90575 31.4947 5.45098 31.23 5.90991L26.73 13.7099C26.5546 14.0136 26.3025 14.2659 25.9988 14.4415C25.6952 14.617 25.3507 14.7096 25 14.7099Z"
                    fill="#44C3FF"
                />
                <path
                    d="M29.5 3.40996C29.763 3.41104 30.0213 3.47993 30.25 3.60996C30.4214 3.70737 30.5716 3.83804 30.6918 3.99431C30.812 4.15058 30.8998 4.3293 30.95 4.51996C31.0523 4.90487 30.9984 5.31461 30.8 5.65996L26.3 13.46C26.1667 13.6868 25.9769 13.8751 25.749 14.0066C25.5211 14.1381 25.2631 14.2081 25 14.21C24.7355 14.2102 24.476 14.1375 24.25 14C24.0779 13.9035 23.9271 13.7731 23.8068 13.6166C23.6865 13.4602 23.5991 13.2811 23.55 13.09C23.4591 12.7208 23.5128 12.3308 23.7 12L28.2 4.19996C28.3333 3.97313 28.5231 3.78478 28.751 3.65332C28.9788 3.52185 29.2369 3.45178 29.5 3.44996V3.40996ZM29.5 2.44996C29.0607 2.4511 28.6294 2.56706 28.2487 2.78632C27.8681 3.00559 27.5514 3.32054 27.33 3.69996L22.83 11.5C22.6655 11.784 22.5588 12.0978 22.516 12.4232C22.4733 12.7487 22.4953 13.0794 22.5808 13.3963C22.6663 13.7132 22.8136 14.0101 23.0143 14.2699C23.2149 14.5296 23.465 14.7472 23.75 14.91C24.1286 15.1334 24.5604 15.2508 25 15.25C25.4393 15.2488 25.8706 15.1329 26.2513 14.9136C26.6319 14.6943 26.9486 14.3794 27.17 14L31.67 6.19996C31.8345 5.9159 31.9412 5.60212 31.984 5.27667C32.0267 4.95122 32.0047 4.62053 31.9192 4.30361C31.8337 3.98669 31.6864 3.68981 31.4857 3.43004C31.2851 3.17027 31.035 2.95274 30.75 2.78996C30.3714 2.56653 29.9396 2.44909 29.5 2.44996Z"
                    fill="white"
                />
            </svg>

            <img src="https://www.q-int.com/assets/logo.svg" />
        </div>
    );
};

export default {
    initialize,
};
